import mustache from "mustache";
import { Key } from "../model/key.model";

export const cloneJSON = (obj: any): any => {
  if (obj === null || obj === undefined || typeof obj !== "object") {
    return obj;
  }

  if (obj instanceof Array) {
    const cloneA = [];
    for (let i = 0; i < obj.length; ++i) {
      cloneA[i] = cloneJSON(obj[i]);
    }
    return cloneA;
  }

  const cloneO: any = {};
  for (const i in obj) {
    cloneO[i] = cloneJSON(obj[i]);
  }
  return cloneO;
};

export const renderJson = (obj: any, data: any): any => {
  if (obj instanceof Array) {
    obj.forEach((v, i) => {
      obj[i] = renderJson(v, data);
    });
  } else if (typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      obj[key] = renderJson(obj[key], data);
    });
  } else {
    try {
      obj = mustache.render(obj, data);
    } catch {
      /* empty */
    }
  }

  return obj;
};

export const maskName = (name: string) => {
  return name.slice(0, 3) + "***";
};

export const maskNumber = (number: string) => {
  return "**** " + number.slice(number.length - 4);
};

export const formatCellNumber = (cellphone: string) => {
  if (cellphone.length === 10) {
    return (
      cellphone.substring(0, 3) +
      " " +
      cellphone.substring(3, 6) +
      " " +
      cellphone.substring(6)
    );
  }

  return cellphone;
};

export const formatIdentificationNumber = (identification: string) => {
  try {
    const nf = new Intl.NumberFormat('es-MX');
    const format = nf.format(parseInt(identification));
  
    return format.replace(/,/g, '.');
  } catch {
    return identification;
  }
};

export const formatBalance = (value: any, noFractionDigits?: boolean) => {
  const valueTransformed = new Intl.NumberFormat("es-CO", {
    minimumFractionDigits: noFractionDigits ? 0 : 2,
  }).format(Number(value));

  const newValue = "$" + valueTransformed;

  return newValue;
};

export const formatValue = (k: Key) => {
  if (k.name.toLowerCase().includes("celular")) {
    return formatCellNumber(k.value);
  } else if (k.name.toLowerCase().includes("identificación")) {
    return formatIdentificationNumber(k.value);
  } else {
    return k.value;
  }
}

export const getTextTypeKeyTagging = (
  keyName: string
): {
  textUrl: string;
  title: string;
} => {

  const wordToSearch = ['celular','correo', 'cedula', 'identificacion', 'email']

  const keyNameType = keyName?.toLocaleLowerCase().normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize()

  const format: Record<string, any> = {
    celular: {
      textUrl: "numero-de-celular",
      title: "Número de celular",
    },
    correo:  {
      textUrl: "correo-electronico",
      title: "Correo electrónico",
    },
    email:  {
      textUrl: "correo-electronico",
      title: "Correo electrónico",
    },
    cedula:  {
      textUrl: "numero-de-identificacion",
      title: "Número de identificación",
    },
    identificacion:  {
      textUrl: "numero-de-identificacion",
      title: "Número de identificación",
    },
  }
  
  for (const value of wordToSearch) {
    if (keyNameType.includes(value)) {
      return format[value];
    }
  }

  return { textUrl: "llave-alfanumerica", title: "Llave alfanumérica" };
};
