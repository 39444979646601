import { NgModule } from "@angular/core";
import {
  Event,
  EventType,
  Router,
  RouterModule,
  Routes,
} from "@angular/router";

declare const window: Window &
  typeof globalThis & {
    dtrum: any;
  };

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./SPI/Spi.module").then((m) => m.SpiModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private readonly router: Router) {
    if (typeof document !== "undefined") {
      window?.dtrum?.enableManualPageDetection();
      this.router.events.subscribe((value: Event) => {
        if (value.type === EventType.ActivationEnd) {
          const snapshot = value.snapshot;
          if (snapshot.routeConfig) {
            const group = snapshot.routeConfig.path;
            const name = snapshot.url.join("/");
            window?.dtrum?.setPage({
              name,
              group,
            });
          }
        }
      });
    }
  }
}
